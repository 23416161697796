import { NgIf, AsyncPipe } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { TuiIcon } from '@taiga-ui/core/components/icon';
import { TuiDropdownOpen } from '@taiga-ui/core/directives/dropdown';
import { tuiSizeBigger } from '@taiga-ui/core/utils/miscellaneous';
import { AbstractTuiControl } from '@taiga-ui/legacy/classes';
import { TUI_TEXTFIELD_SIZE } from '@taiga-ui/legacy/directives';
import { PolymorpheusOutlet, PolymorpheusTemplate, PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk/utils/miscellaneous';

/**
 * @deprecated: drop in v5.0 use {@link TuiChevron}
 */
function TuiArrowComponent_tui_icon_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-icon", 2);
  }
  if (rf & 2) {
    const src_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("icon", src_r1);
  }
}
function TuiArrowComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const TUI_ARROW_DEFAULT_OPTIONS = {
  iconSmall: '@tui.chevron-down',
  iconLarge: '@tui.chevron-down'
};
/**
 * @deprecated: drop in v5.0
 * Default parameters for arrow component
 */
const TUI_ARROW_OPTIONS = tuiCreateToken(TUI_ARROW_DEFAULT_OPTIONS);
function tuiArrowOptionsProvider(options) {
  return tuiProvideOptions(TUI_ARROW_OPTIONS, options, TUI_ARROW_DEFAULT_OPTIONS);
}

/**
 * @deprecated: drop in v5.0 use {@link TuiChevron}
 */
class TuiArrowComponent {
  constructor() {
    this.control = inject(AbstractTuiControl, {
      optional: true
    });
    this.textfieldSize = inject(TUI_TEXTFIELD_SIZE);
    this.options = inject(TUI_ARROW_OPTIONS);
    this.directive = inject(TuiDropdownOpen, {
      optional: true
    });
  }
  get rotated() {
    return this.directive?.tuiDropdownOpen || !!this.control.pseudoOpen || false;
  }
  get small() {
    return !tuiSizeBigger(this.textfieldSize.size);
  }
  get arrowIcon() {
    return !this.small ? this.options.iconLarge : this.options.iconSmall;
  }
  static {
    this.ɵfac = function TuiArrowComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiArrowComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiArrowComponent,
      selectors: [["tui-arrow"]],
      hostVars: 4,
      hostBindings: function TuiArrowComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("_rotated", ctx.rotated)("_small", ctx.small);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 4,
      consts: [[3, "icon", 4, "polymorpheusOutlet"], [4, "ngIf"], [3, "icon"]],
      template: function TuiArrowComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiArrowComponent_tui_icon_0_Template, 1, 1, "tui-icon", 0)(1, TuiArrowComponent_ng_container_1_Template, 1, 0, "ng-container", 1);
          i0.ɵɵpipe(2, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("polymorpheusOutlet", ctx.arrowIcon);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 2, ctx.directive == null ? null : ctx.directive.tuiDropdownOpenChange));
        }
      },
      dependencies: [PolymorpheusOutlet, NgIf, AsyncPipe, TuiIcon],
      styles: ["[_nghost-%COMP%]{transition-property:transform;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;display:block;cursor:pointer;pointer-events:none}._rotated[_nghost-%COMP%]{transform:rotate(180deg)}tui-icon[_ngcontent-%COMP%]{border:.25rem solid transparent}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiArrowComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-arrow',
      imports: [PolymorpheusOutlet, PolymorpheusTemplate, NgIf, AsyncPipe, TuiIcon],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<tui-icon\n    *polymorpheusOutlet=\"arrowIcon as src\"\n    [icon]=\"src\"\n/>\n<ng-container *ngIf=\"directive?.tuiDropdownOpenChange | async\" />\n",
      styles: [":host{transition-property:transform;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;display:block;cursor:pointer;pointer-events:none}:host._rotated{transform:rotate(180deg)}tui-icon{border:.25rem solid transparent}\n"]
    }]
  }], null, {
    rotated: [{
      type: HostBinding,
      args: ['class._rotated']
    }],
    small: [{
      type: HostBinding,
      args: ['class._small']
    }]
  });
})();
const TUI_ARROW = new PolymorpheusComponent(TuiArrowComponent);

/**
 * @deprecated: drop in v5.0 use {@link TuiChevron}
 */
const TUI_ARROW_DEFAULT_MODE = {
  interactive: TUI_ARROW,
  disabled: TUI_ARROW
};
/**
 * @deprecated: drop in v5.0 use {@link TuiChevron}
 */
const TUI_ARROW_MODE = tuiCreateToken(TUI_ARROW_DEFAULT_MODE);
/**
 * @deprecated: drop in v5.0 use {@link TuiChevron}
 */
function tuiArrowModeProvider(options) {
  return tuiProvideOptions(TUI_ARROW_MODE, options, TUI_ARROW_DEFAULT_MODE);
}

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_ARROW, TUI_ARROW_DEFAULT_MODE, TUI_ARROW_DEFAULT_OPTIONS, TUI_ARROW_MODE, TUI_ARROW_OPTIONS, TuiArrowComponent, tuiArrowModeProvider, tuiArrowOptionsProvider };
